import { Page, Divider } from '../../common/page_components/PageComponents';
import {
    OverviewSection,
    PlaylistsSection,
    StoriesSection,
    TravelSection,
} from './BlogComponents';

function Blog() {
    return (
        <Page>
            <OverviewSection />
            <Divider />
            <PlaylistsSection />
            <Divider />
            <StoriesSection />
            <Divider />
            <TravelSection />
        </Page>
    );
}

export default Blog;
