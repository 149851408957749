import {
    Page,
    PageSectionGroup,
    Divider,
} from '../../common/page_components/PageComponents';
import {
    ExplanationSection,
    ProjectsSection,
    GitHubSection,
} from './CodingComponents';

function Coding() {
    return (
        <Page>
            <PageSectionGroup className="full_height">
                <ExplanationSection />
                <ProjectsSection />
            </PageSectionGroup>
            <Divider />
            <GitHubSection />
        </Page>
    );
}

export default Coding;
