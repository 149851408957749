const commonContent = {
    loading: 'Loading...',
    noImages: 'No images found.',
    footerText: '© 2023 AEN. Built by Ali Emre Nebiler using React.',
    notFound: 'Nothing to see here.',
};

const homeContent = {
    primaryWelcomeMessages: [
        'Good To See You.',
        'Finally, You Are Here.',
        'Hey There.',
        'Hi There.',
        'Oh, There You Are.',
        'Wow, You Made It.',
    ],
    secondaryWelcomeMessage: 'Welcome!',
    aboutTitle: 'About',
    aboutText: [
        'I am Ali Emre, a computer engineer who has also designing skills. ' +
            'I learnt many languages - and still learning - with different experiences. ' +
            'Example, I am learning React while developing this website.',
        'My main goal is to combine my design and coding skills. ' +
            'I believe that the design factor has an impact on any system, which includes user experience.',
        'This is kind of a portfolio website. You can find interesting projects here and there.',
        'Enjoy!',
    ],
};

const codingContent = {
    explanationTextHighlight: 'Here are my coding projects.',
    explanationText:
        'Most of them are from university courses and internships. ' +
        'Due to time limitations, some are completed, some are not. ' +
        'Although the variety is a lot here, I am currently working mainly on React and Python.',
    noProjects: 'No projects found.',
    projects: [
        {
            url: 'https://github.com/aliemrenebiler/Cancer_Prediction_with_AI',
            title: 'Cancer Prediction With AI',
            text:
                'Graduation Thesis. Bioinformatics project about colorectal cancer (CRC). ' +
                'Main goals were classifing CRC with binary classification methods, and finding ' +
                'new potential survival markers (biomarkers) with survival analysis.',
        },
        {
            url: 'https://github.com/aliemrenebiler/Shuttlefly_Effect_Game',
            title: 'Shuttlefly Effect Game',
            text:
                'Computer Engineering Midterm Project. This game is a storytelling space game. ' +
                'It is being developed with Flutter. The story starts with a shuttle accident in space, ' +
                'the goal is to make astronauts find a way to go back to planet Earth.',
        },
        {
            url: 'https://github.com/aliemrenebiler/2048_with_Hand_Detection',
            title: '2048 With Hand Detection',
            text:
                'Embedded Systems Term Project. Works on Rasperry Pi 2 with a camera. ' +
                'It can be played on any system which can run Python. You can simply swipe your hand ' +
                'to four ways (up, down, left and right) in order to merge cells.',
        },
        {
            url: 'https://github.com/aliemrenebiler/Vehicle_Control_with_Eye_Detection',
            title: 'Vehicle Control With Eye Detection',
            text:
                'This project is for the people who has spinal cord paralysis. These peope use ' +
                'wheelchairs and cannot control the chair without any help. The aim is to able the person ' +
                'control the chair with eye movements.',
        },
        {
            url: 'https://github.com/aliemrenebiler/Nokia_Internship_Projects',
            title: 'Nokia Internship Projects',
            text:
                'Python and Docker based automation project. On Nokia SR Linux devices, you can configure ' +
                'the device with a config (JSON-like) file. The main goal is to connect to an SR Linux ' +
                'device with simple inputs and validate multiple config files with a single start.',
        },
        {
            url: 'https://github.com/aliemrenebiler/Yildiz_Rover_UI',
            title: 'Yildiz Rover UI',
            text:
                'Yildiz Rover is an interdisciplinary robotics team at Yildiz Technical University, ' +
                'which builds rover (space exploration vehicle). Rover can collect information and examine samples. ' +
                'This project was developed to visualize the data obtained by the rover, in control center.',
        },
        {
            url: 'https://github.com/aliemrenebiler/RGBulb_App',
            title: 'RGBulb App',
            text:
                'An Android app to control our IoT RGB LED module. The module was made with NodeMCU and a RGB LED. ' +
                'The Flutter app uses MQTT protocol to send requested LED information. ' +
                'Can be controlled from external network.',
        },
        {
            url: 'https://github.com/aliemrenebiler/Stock_Management_System',
            title: 'Stock Management System',
            text:
                'Database Management Term Project. This is a very flexible stock management desktop ' +
                'app developed with Flutter. It uses SQLite, stores the data locally.',
        },
    ],
    gitHubTitle: 'Other Projects',
    gitHubText: 'Check my other project repositories on GitHub.',
};

const artContent = {
    explanationTextHighlight: 'I do love art!',
    explanationText: 'This page is a freespace to share my artistic character.',
    noGalleries: 'No galleries found.',
    galleries: [
        {
            title: 'TEDx Yildiz Technical University',
            folder: 'design-1-tedx',
            fileNames: Array.from(
                { length: 13 },
                (_, i) => `AEN-tedx-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Yeni Fikirler, Yeni Projeler',
            folder: 'design-2-yfyp',
            fileNames: Array.from(
                { length: 10 },
                (_, i) => `AEN-yfyp-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'BKLMUN',
            folder: 'design-3-bklmun',
            fileNames: Array.from(
                { length: 8 },
                (_, i) => `AEN-bklmun-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'University 4Society @YTU',
            folder: 'design-4-u4s',
            fileNames: Array.from(
                { length: 7 },
                (_, i) => `AEN-u4s-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Logo Designs',
            folder: 'design-5-logos',
            fileNames: Array.from(
                { length: 14 },
                (_, i) => `AEN-logo-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Istanbul Photos',
            folder: 'photo-1-istanbul',
            fileNames: Array.from(
                { length: 12 },
                (_, i) => `AEN-istanbul-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Sunrise Photos',
            folder: 'photo-2-sunrise',
            fileNames: Array.from(
                { length: 4 },
                (_, i) => `AEN-sunrise-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Izmir Photos',
            folder: 'photo-3-izmir',
            fileNames: Array.from(
                { length: 8 },
                (_, i) => `AEN-izmir-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Abant Photos',
            folder: 'photo-4-abant',
            fileNames: Array.from(
                { length: 6 },
                (_, i) => `AEN-abant-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Eskisehir Photos',
            folder: 'photo-5-eskisehir',
            fileNames: Array.from(
                { length: 5 },
                (_, i) => `AEN-eskisehir-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Lens From Galata Photos',
            folder: 'photo-6-lens',
            fileNames: Array.from(
                { length: 6 },
                (_, i) => `AEN-lens-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
        {
            title: 'Serbia Photos',
            folder: 'photo-7-serbia',
            fileNames: Array.from(
                { length: 11 },
                (_, i) => `AEN-serbia-${String(i + 1).padStart(2, '0')}.jpg`
            ),
        },
    ],
};

const blogContent = {
    description: {
        highlight: 'Playlists, stories and more...',
        main: 'Here are any content that you can know me better and have fun.',
    },
    overview: {
        playlists: {
            title: 'Playlists',
            text: 'Lists on Spotify for all moods.',
        },
        stories: {
            title: 'Stories',
            text: 'Medium stories about some topics.',
        },
        travel: {
            title: 'Travel',
            text: 'Lists on Google Maps for travelers.',
        },
    },
    playlists: [
        { title: 'Club', id: '5tKxJGAJPgSmcKwEXBsHOk' },
        { title: 'Rumble', id: '27KdW9opqMkJ7j3DOGRjSy' },
        { title: 'Turkish', id: '66xdqOJGHidWRCaBvVCXAv' },
        { title: 'Acoustic', id: '7wgbVIn5uMPezYWMBOJHgO' },
        { title: 'Quiet & Sleepy', id: '7b6TeKk1bNBFhlNdIhJZ1z' },
        { title: 'Retro', id: '6hirPf9yUnR4aRgxTDPMbV' },
        { title: 'Nostalgia', id: '11UWmr6yocYJT2jeiDS0Ry' },
        { title: 'Melody', id: '7L7NfzBZDdCjMeIj2LroR4' },
        { title: 'WTF', id: '5blmNrsHhhVL0J0YIqNSJX' },
    ],
    noStories: 'No stories found.',
    mediumText: 'Read my other posts on Medium.',
    travels: [
        {
            name: 'İstanbul (Turkiye)',
            maps: [
                {
                    title: 'Breakfast',
                    url: 'https://maps.app.goo.gl/1UUbwbJUHxZDPiEW7',
                },
                {
                    title: 'Cafe',
                    url: 'https://maps.app.goo.gl/Nj2F2XTrjYTDmbng6',
                },
                {
                    title: 'Bakery',
                    url: 'https://maps.app.goo.gl/ubuJfntLRDwKDngs9',
                },
                {
                    title: 'Restaurants',
                    url: 'https://maps.app.goo.gl/dyj24qcPCST2miHv5',
                },
                {
                    title: 'Pub & Bar',
                    url: 'https://maps.app.goo.gl/y8Pg2awqVv8jUomC9',
                },
                {
                    title: 'Historical',
                    url: 'https://maps.app.goo.gl/fqq1Z3nhYaCGJXJC6',
                },
                {
                    title: 'Other',
                    url: 'https://maps.app.goo.gl/JbpDE5V44pz4ZW4i8',
                },
            ],
        },
        {
            name: 'İzmir (Turkiye)',
            maps: [
                {
                    title: 'Breakfast',
                    url: 'https://maps.app.goo.gl/3DcRBM1RSwnqKix8A',
                },
                {
                    title: 'Cafe',
                    url: 'https://maps.app.goo.gl/JibedEe2HxLizL3N8',
                },
                {
                    title: 'Bakery',
                    url: 'https://maps.app.goo.gl/u2c7nZ7fJFa7b5fH6',
                },
                {
                    title: 'Restaurants',
                    url: 'https://maps.app.goo.gl/UXAUNnDDYk3SikUw5',
                },
                {
                    title: 'Pub & Bar',
                    url: 'https://maps.app.goo.gl/jdzk398KnvCZNW4LA',
                },
                {
                    title: 'Historical',
                    url: 'https://maps.app.goo.gl/ciRznDcNvzCadu81A',
                },
                {
                    title: 'Other',
                    url: 'https://maps.app.goo.gl/7Zg1G3WCrabB41957',
                },
            ],
        },
        {
            name: 'Turkiye',
            maps: [
                {
                    title: 'Manisa',
                    url: 'https://maps.app.goo.gl/f1QAJDf5FbNp2aff7',
                },
            ],
        },
        {
            name: 'Montenegro',
            maps: [
                {
                    title: 'Kotor',
                    url: 'https://maps.app.goo.gl/9FkjEDTdrhtLy16a8',
                },
                {
                    title: 'Budva',
                    url: 'https://maps.app.goo.gl/qJaEL4a3rpNbTN4g8',
                },
                {
                    title: 'Podgorica',
                    url: 'https://maps.app.goo.gl/1H2bTTxQqv6YtFba6',
                },
            ],
        },
        {
            name: 'Austria',
            maps: [
                {
                    title: 'Vienna',
                    url: 'https://maps.app.goo.gl/Wv3nkUssYPPhJi8Z8',
                },
                {
                    title: 'Hallstatt',
                    url: 'https://maps.app.goo.gl/V4d29MySm4EnaPa86',
                },
            ],
        },
        {
            name: 'Serbia',
            maps: [
                {
                    title: 'Belgrade',
                    url: 'https://maps.app.goo.gl/3ySB5gBsAuVdHRwL6',
                },
                {
                    title: 'Novi Sad',
                    url: 'https://maps.app.goo.gl/cHuCn5FHEbBEqC3SA',
                },
            ],
        },
        {
            name: 'Greece',
            maps: [
                {
                    title: 'Samos',
                    url: 'https://maps.app.goo.gl/XEiUyeW8C1sKxFQn7',
                },
            ],
        },
        {
            name: 'Netherlands',
            maps: [
                {
                    title: 'Amsterdam',
                    url: 'https://maps.app.goo.gl/PbNSXeDFYwvw2QFH7',
                },
            ],
        },
        {
            name: 'Poland',
            maps: [
                {
                    title: 'Krakow',
                    url: 'https://maps.app.goo.gl/iZjqF2W2EZXSjG9k8',
                },
            ],
        },
        {
            name: 'Hungary',
            maps: [
                {
                    title: 'Budapest',
                    url: 'https://maps.app.goo.gl/HLfLwWb7i3Gdko5T7',
                },
            ],
        },
    ],
};

const releaseNotesContent = {
    title: 'Release Notes',
    releaseNotes: [
        {
            version: 'v1.2.0',
            name: 'Lifestyle Blog Update',
            date: 'September 24, 2024',
            majorChanges: [
                'Added playlists section for Spotify playlists.',
                'Added travel section for Google Maps lists.',
                'Updated blog content as stories section.',
            ],
        },
        {
            version: 'v1.1.2',
            name: 'Bugfix: Page Size & Performance',
            date: 'March 24, 2024',
            majorChanges: [
                'Changed viewport metadata to fix wrong height on android browsers.',
                'Updated gallery carousel background size, added scroll lock when carousel is visible.',
            ],
            minorChanges: [
                'Updated page components code for better readability.',
            ],
        },
        {
            version: 'v1.1.1',
            name: 'Bugfix: Theme & Alignment',
            date: 'March 22, 2024',
            majorChanges: [
                'Fixed wrong shade color bug caused by 3-digit hex codes.',
                'Aligned release note titles properly.',
            ],
        },
        {
            version: 'v1.1.0',
            name: 'Color Theme Update',
            date: 'March 17, 2024',
            majorChanges: [
                'Updated the general color theme logic. See new themes by clicking on the AEN logo.',
                'Simplified CSS variables for color themes.',
                'Updated previous color themes, added new ones.',
            ],
            minorChanges: [
                'Added release notes page (this page).',
                'Removed development section from home page.',
            ],
        },
        {
            version: 'v1.0.1',
            name: 'Gallery Update',
            date: 'October 2, 2023',
            majorChanges: [
                'Updated gallery image fetching system for better performance.',
                'Added skeleton animation to images.',
                'Added fade in animations to images.',
            ],
            minorChanges: ['Added development section to home page.'],
        },
        {
            version: 'v1.0.0',
            name: 'Reborn Release',
            date: 'September 5, 2023',
            majorChanges: [
                'Created a better and unique UI.',
                'Added many generic components like navbar, sidebar, page sections, and gallery.',
                'Developed a color theme system with lots of colors. Try it by clicking on the logo on the navigation bar.',
            ],
        },
    ],
};

export {
    commonContent,
    homeContent,
    codingContent,
    artContent,
    blogContent,
    releaseNotesContent,
};
