import { useEffect, useState } from 'react';

import {
    FaRegHandPointDown,
    FaRegHandPointLeft,
    FaRegHandPointRight,
} from 'react-icons/fa';

import {
    goToSection,
    useBarType,
    useWindowSize,
    useWindowSizeLimits,
} from '../../../providers/WindowProvider';
import {
    PageSectionGroup,
    PageSection,
    SectionItem,
    SectionRow,
    Divider,
    Spacing,
} from '../../common/page_components/PageComponents';
import { blogContent } from '../../../assets/content/Contents';
import PlaceholderMessage from '../../common/placeholder/PlaceholderMessage';
import MediumService from '../../../services/BlogService';

import './Blog.css';

function OverviewSection() {
    const windowSize = useWindowSize();
    const windowSizeLimits = useWindowSizeLimits();
    const isScreenWidthSmall = windowSize.width < windowSizeLimits.medium;
    const [screenWidthSmall, setScreenWidthSmall] =
        useState(isScreenWidthSmall);
    const barType = useBarType();

    useEffect(() => {
        setScreenWidthSmall(isScreenWidthSmall);
    }, [isScreenWidthSmall]);

    function OverviewBox(props) {
        return (
            <button
                id={props.id && props.id}
                className="outlined overview_box"
                onClick={props.onClick}
            >
                <h3>{props.title}</h3>
                <p className="small">{props.text}</p>
                {screenWidthSmall ? undefined : (
                    <div className="go_down_icon">
                        <FaRegHandPointDown size="30" />
                    </div>
                )}
            </button>
        );
    }

    return (
        <PageSectionGroup className="full_height">
            <PageSection className="background_color_shade_2">
                <SectionItem>
                    <p>
                        <b>{blogContent.description.highlight}</b>{' '}
                        {blogContent.description.main}
                    </p>
                </SectionItem>
            </PageSection>
            <PageSection className="expand full_width">
                <SectionRow
                    columnCount={screenWidthSmall ? 1 : 3}
                    className="expand"
                >
                    <SectionItem className="expand">
                        <OverviewBox
                            id="playlists_overview"
                            title={blogContent.overview.playlists.title}
                            text={blogContent.overview.playlists.text}
                            onClick={() => {
                                goToSection(
                                    'playlists',
                                    barType === 'navbar' ? 80 : 0
                                );
                            }}
                        ></OverviewBox>
                    </SectionItem>
                    <SectionItem className="expand">
                        <OverviewBox
                            id="stories_overview"
                            title={blogContent.overview.stories.title}
                            text={blogContent.overview.stories.text}
                            onClick={() => {
                                goToSection(
                                    'stories',
                                    barType === 'navbar' ? 80 : 0
                                );
                            }}
                        />
                    </SectionItem>
                    <SectionItem className="expand">
                        <OverviewBox
                            id="travel_overview"
                            title={blogContent.overview.travel.title}
                            text={blogContent.overview.travel.text}
                            onClick={() => {
                                goToSection(
                                    'travel',
                                    barType === 'navbar' ? 80 : 0
                                );
                            }}
                        />
                    </SectionItem>
                </SectionRow>
            </PageSection>
        </PageSectionGroup>
    );
}

function PlaylistsSection() {
    const [loading, setLoading] = useState(true);
    const [playlistIndex, setPlaylistIndex] = useState(
        Math.floor(Math.random() * blogContent.playlists.length)
    );

    function PlaylistsNavBar() {
        const windowSize = useWindowSize();
        const windowSizeLimits = useWindowSizeLimits();

        const nextPlaylist = () => {
            setLoading(true);
            setPlaylistIndex((index) =>
                index + 1 < blogContent.playlists.length ? index + 1 : 0
            );
        };

        const prevPlaylist = () => {
            setLoading(true);
            setPlaylistIndex((index) =>
                0 < index ? index - 1 : blogContent.playlists.length - 1
            );
        };

        function PlaylistsNavBarButton(props) {
            return (
                <button
                    className="hollow playlists_navbar_button"
                    onClick={props.onClick}
                >
                    {props.children}
                </button>
            );
        }

        return (
            <div className="playlists_navbar">
                <PlaylistsNavBarButton
                    onClick={() => {
                        prevPlaylist();
                    }}
                >
                    <div id="playlists_navbar_button_prev_icon">
                        <FaRegHandPointLeft size="25" />
                    </div>
                    {windowSize.width > windowSizeLimits.xsmall && 'PREV'}
                </PlaylistsNavBarButton>
                <div className="playlists_navbar_title">
                    <p>{blogContent.playlists[playlistIndex].title}</p>
                </div>
                <PlaylistsNavBarButton
                    onClick={() => {
                        nextPlaylist();
                    }}
                >
                    {windowSize.width > windowSizeLimits.xsmall && 'NEXT'}
                    <div id="playlists_navbar_button_next_icon">
                        <FaRegHandPointRight size="25" />
                    </div>
                </PlaylistsNavBarButton>
            </div>
        );
    }

    return (
        <PageSectionGroup id="playlists" className="full_height">
            <PageSection className="background_color_shade_2">
                <SectionItem>
                    <h3>Spotify Playlist</h3>
                </SectionItem>
            </PageSection>
            <PlaylistsNavBar />
            <Divider />
            <PageSection className="expand">
                <SectionItem className="full_width expand">
                    <div
                        className={
                            loading
                                ? 'skeleton spotify_container'
                                : 'spotify_container'
                        }
                    >
                        <iframe
                            title={blogContent.playlists[playlistIndex].title}
                            src={`https://open.spotify.com/embed/playlist/${blogContent.playlists[playlistIndex].id}?utm_source=generator`}
                            style={{
                                flex: 1,
                                width: '100%',
                                height: '100%',
                                border: 'unset',
                                opacity: loading ? 0 : 1,
                                transition: 'opacity 0.2s ease',
                            }}
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                            onLoad={() => {
                                setLoading(false);
                            }}
                        ></iframe>
                    </div>
                </SectionItem>
            </PageSection>
        </PageSectionGroup>
    );
}

function StoriesSection() {
    const [loading, setLoading] = useState(true);
    const [stories, setStories] = useState([]);

    useEffect(() => {
        async function fetchStories() {
            await MediumService.fetchMediumContent(
                '@aliemrenebiler',
                0,
                10
            ).then((fetchedStories) => {
                setStories(MediumService.arrangeContent(fetchedStories));
                setLoading(false);
            });
        }
        fetchStories();
    }, []);

    function StoryPlaceholder(props) {
        return (
            <div
                className={`skeleton story_placeholder${
                    props.className ? ' ' + props.className : ''
                }`}
            ></div>
        );
    }

    function StoryButton(props) {
        return (
            <button
                className="outlined story_button"
                onClick={() => {
                    props.url && window.open(props.url, '_blank');
                }}
            >
                <p>{props.title}</p>
                <p className="small">{props.text}</p>
            </button>
        );
    }

    function MediumSection() {
        function MediumButton() {
            return (
                <button
                    onClick={() => {
                        window.open(
                            'https://medium.com/@aliemrenebiler',
                            '_blank'
                        );
                    }}
                    className="outlined"
                    id="medium_button"
                >
                    @aliemrenebiler on Medium
                </button>
            );
        }

        return (
            <PageSection>
                <SectionItem>
                    <p>{blogContent.mediumText}</p>
                </SectionItem>
                <Spacing height="10px" />
                <SectionItem>
                    <MediumButton />
                </SectionItem>
            </PageSection>
        );
    }

    function renderStories() {
        if (loading) {
            return (
                <PageSection className="align_top expand">
                    <SectionItem className="full_width">
                        <StoryPlaceholder />
                    </SectionItem>
                    <SectionItem className="full_width">
                        <StoryPlaceholder className="height_150" />
                    </SectionItem>
                </PageSection>
            );
        } else if (stories.length < 1) {
            return (
                <PlaceholderMessage
                    message={blogContent.noStories}
                    className="expand"
                />
            );
        } else {
            return (
                <PageSection className="align_top expand">
                    {stories.map((story, key) => (
                        <SectionItem key={key} className="full_width">
                            <StoryButton
                                title={story.title}
                                text={story.date}
                                url={story.url}
                            />
                        </SectionItem>
                    ))}
                </PageSection>
            );
        }
    }

    return (
        <PageSectionGroup id="stories" className="full_height">
            <PageSection className="background_color_shade_2">
                <SectionItem>
                    <h3>Medium Stories</h3>
                </SectionItem>
            </PageSection>
            {renderStories()}
            <Divider />
            <MediumSection />
        </PageSectionGroup>
    );
}

function TravelSection() {
    const windowSize = useWindowSize();
    const windowSizeLimits = useWindowSizeLimits();
    const isScreenWidthSmall = windowSize.width < windowSizeLimits.small;
    const [columnCount, setColumnCount] = useState(isScreenWidthSmall ? 1 : 2);

    useEffect(() => {
        setColumnCount(isScreenWidthSmall ? 1 : 2);
    }, [isScreenWidthSmall]);

    function CityMapBox(props) {
        return (
            <div className="outlined city_map_box">
                <h3>{props.name}</h3>
                <div className="buttons">
                    {props.maps.map((map, index) => (
                        <button
                            key={index}
                            className="outlined city_map_button"
                            onClick={() => {
                                map.url && window.open(map.url, '_blank');
                            }}
                        >
                            {map.title}
                        </button>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <PageSectionGroup id="travel" className="full_height">
            <PageSection className="background_color_shade_2">
                <SectionItem>
                    <h3>Google Maps For Travelers</h3>
                </SectionItem>
            </PageSection>
            <PageSection className="align_top expand">
                <SectionRow
                    columnCount={columnCount}
                    className="expand align_top"
                >
                    {blogContent.travels.map((city, key) => {
                        return (
                            <SectionItem key={key} className="expand">
                                <CityMapBox name={city.name} maps={city.maps} />
                            </SectionItem>
                        );
                    })}
                </SectionRow>
            </PageSection>
        </PageSectionGroup>
    );
}

export { OverviewSection, PlaylistsSection, StoriesSection, TravelSection };
