import { useEffect, Children } from 'react';

import {
    setFullPageHeight,
    useBarType,
} from '../../../providers/WindowProvider';

import './PageComponents.css';

const setClassName = (className, presetClassName) => {
    return `${presetClassName} ${className ? className : ''}`.trim();
};

function Page(props) {
    const barType = useBarType();
    useEffect(() => {
        setFullPageHeight(barType);
    }, [barType]);

    return <div className="page">{props.children}</div>;
}

function PageSection(props) {
    const barType = useBarType();
    useEffect(() => {
        setFullPageHeight(barType);
    }, [barType]);

    return (
        <div
            id={props.id && props.id}
            className={setClassName(props.className, 'page_section')}
        >
            <div className="page_section_container">{props.children}</div>
        </div>
    );
}

function PageSectionGroup(props) {
    const barType = useBarType();
    useEffect(() => {
        setFullPageHeight(barType);
    }, [barType]);

    return (
        <div
            id={props.id && props.id}
            className={setClassName(props.className, 'page_section_group')}
        >
            {props.children}
        </div>
    );
}

function SectionRow(props) {
    const arrayChildren = Children.toArray(props.children);
    let columnContents = [];
    if (props.columnCount) {
        columnContents = Array(props.columnCount)
            .fill()
            .map(() => []);
        arrayChildren.forEach((child, key) => {
            columnContents[key % props.columnCount].push(child);
        });
    } else {
        columnContents = arrayChildren;
    }

    return (
        <div className={setClassName(props.className, 'page_section_row')}>
            {columnContents.map((content, key) => {
                return (
                    <div key={key} className="page_section_row_column">
                        {content}
                    </div>
                );
            })}
        </div>
    );
}

function SectionItem(props) {
    return (
        <div className={setClassName(props.className, 'page_section_item')}>
            {props.children}
        </div>
    );
}

function Spacing(props) {
    return (
        <div
            className="spacing"
            style={{ width: props.width, height: props.height }}
        ></div>
    );
}

function Divider() {
    return <div className="divider"></div>;
}

export {
    Page,
    PageSectionGroup,
    PageSection,
    SectionRow,
    SectionItem,
    Spacing,
    Divider,
};
