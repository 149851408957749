import { useEffect, useState } from 'react';
import { FaRegHandPointLeft, FaRegHandPointRight } from 'react-icons/fa';

import {
    useWindowSize,
    useWindowSizeLimits,
} from '../../../providers/WindowProvider';
import {
    PageSection,
    SectionItem,
    Divider,
} from '../../common/page_components/PageComponents';
import { Gallery, GalleryImage } from '../../common/gallery/Gallery';
import { Carousel, CarouselImage } from '../../common/carousel/Carousel';
import { artContent } from '../../../assets/content/Contents';
import PlaceholderMessage from '../../common/placeholder/PlaceholderMessage';
import ImageService from '../../../services/ImageService';

import './Art.css';

function ExplanationSection() {
    return (
        <PageSection id="art_explanation_section">
            <SectionItem>
                <p>
                    <b>{artContent.explanationTextHighlight}</b>{' '}
                    {artContent.explanationText}
                </p>
            </SectionItem>
        </PageSection>
    );
}

function GalleryNavBar(props) {
    const windowSize = useWindowSize();
    const windowSizeLimits = useWindowSizeLimits();

    const nextGallery = () => {
        props.setGalleryIndex((galleryIndex) =>
            galleryIndex + 1 < artContent.galleries.length
                ? galleryIndex + 1
                : 0
        );
    };

    const prevGallery = () => {
        props.setGalleryIndex((galleryIndex) =>
            0 < galleryIndex
                ? galleryIndex - 1
                : artContent.galleries.length - 1
        );
    };

    function GalleryNavBarButton(props) {
        return (
            <button
                className="hollow gallery_navbar_button"
                onClick={props.onClick}
            >
                {props.children}
            </button>
        );
    }

    return (
        <div className="gallery_navbar">
            <GalleryNavBarButton
                onClick={() => {
                    prevGallery();
                }}
            >
                <div id="gallery_navbar_button_prev_icon">
                    <FaRegHandPointLeft size="25" />
                </div>
                {windowSize.width > windowSizeLimits.xsmall && 'PREV'}
            </GalleryNavBarButton>
            <div className="gallery_navbar_title">
                <p>{artContent.galleries[props.galleryIndex].title}</p>
            </div>
            <GalleryNavBarButton
                onClick={() => {
                    nextGallery();
                }}
            >
                {windowSize.width > windowSizeLimits.xsmall && 'NEXT'}
                <div id="gallery_navbar_button_next_icon">
                    <FaRegHandPointRight size="25" />
                </div>
            </GalleryNavBarButton>
        </div>
    );
}

function GallerySection() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentGalleryIndex, setCurrentGalleryIndex] = useState(
        Math.floor(Math.random() * artContent.galleries.length)
    );
    const [galleryLoading, setGalleryLoading] = useState(true);
    const [carouselVisibility, setCarouselVisibility] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);

    // Get image names at start
    useEffect(() => {
        if (artContent.galleries && artContent.galleries.length > 0) {
            setGalleryLoading(true);
            let newImageUrls = ImageService.getImageFilePaths(
                artContent.galleries[currentGalleryIndex]
            );
            setImageUrls(newImageUrls);
            setGalleryLoading(false);
        }
    }, [currentGalleryIndex]);

    // Open carousel with the clicked image
    const openCarouselWithCurrentImage = (key) => {
        setCurrentImageIndex(key);
        setCarouselVisibility(true);
    };

    function renderGallery() {
        if (!artContent.galleries || artContent.galleries.length < 1) {
            return (
                <PlaceholderMessage
                    message={artContent.noGalleries}
                    className="expand"
                />
            );
        } else {
            return (
                <>
                    <GalleryNavBar
                        galleryIndex={currentGalleryIndex}
                        setGalleryIndex={setCurrentGalleryIndex}
                    />
                    <Divider />
                    <Gallery
                        title={artContent.galleries[currentGalleryIndex].title}
                        onClickImage={openCarouselWithCurrentImage}
                        loading={galleryLoading}
                    >
                        {imageUrls.length > 0 &&
                            imageUrls.map((url, key) => {
                                return (
                                    <GalleryImage
                                        key={key}
                                        src={url}
                                        alt={
                                            artContent.galleries[
                                                currentGalleryIndex
                                            ].title
                                        }
                                    />
                                );
                            })}
                    </Gallery>
                    <Carousel
                        currentImageIndex={currentImageIndex}
                        onClickClose={setCarouselVisibility}
                        visible={carouselVisibility}
                    >
                        {imageUrls.length > 0 &&
                            imageUrls.map((url, key) => {
                                return <CarouselImage key={key} src={url} />;
                            })}
                    </Carousel>
                </>
            );
        }
    }

    return renderGallery();
}

export { ExplanationSection, GallerySection };
