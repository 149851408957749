import {
    Page,
    PageSectionGroup,
} from '../../common/page_components/PageComponents';
import { ExplanationSection, GallerySection } from './ArtComponent';

function Art() {
    return (
        <Page>
            <PageSectionGroup className="full_height">
                <ExplanationSection />
                <GallerySection />
            </PageSectionGroup>
        </Page>
    );
}

export default Art;
