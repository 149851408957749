import { releaseNotesContent } from '../../../assets/content/Contents';
import {
    PageSection,
    SectionItem,
    Divider,
    Spacing,
} from '../../common/page_components/PageComponents';

import './ReleaseNotes.css';

function TopSection() {
    return (
        <PageSection id="release_notes_top_section">
            <SectionItem>
                <h2>{releaseNotesContent.title}</h2>
            </SectionItem>
        </PageSection>
    );
}

function ReleaseNotesSection() {
    function VersionTitle(props) {
        return (
            <div className="align_left version_title">
                <div className="version_box">
                    <h4>{props.version}</h4>
                </div>
                <h3 className="version_name">{props.name}</h3>
            </div>
        );
    }

    function VersionChanges(props) {
        return (
            <>
                {props.changes.map((text, key) => (
                    <div className="align_left version_changes">
                        <div
                            className={
                                props.size
                                    ? `${props.size} change_bullet_container`
                                    : 'change_bullet_container'
                            }
                        >
                            <div
                                className={
                                    props.size
                                        ? `${props.size} change_bullet`
                                        : 'change_bullet'
                                }
                            ></div>
                        </div>
                        <div className="change_text_container">
                            <p
                                key={key}
                                className={
                                    props.size
                                        ? `${props.size} align_left`
                                        : 'align_left'
                                }
                            >
                                {text}
                            </p>
                        </div>
                    </div>
                ))}
            </>
        );
    }

    return (
        <>
            {releaseNotesContent.releaseNotes.map((releaseNote, index) => (
                <>
                    {index > 0 && <Divider />}
                    <PageSection id="release_notes_section">
                        <SectionItem className="align_left">
                            <p className="release_date small align_left">
                                {releaseNote.date}
                            </p>
                        </SectionItem>
                        <SectionItem className="align_left">
                            <VersionTitle
                                version={releaseNote.version}
                                name={releaseNote.name}
                            />
                        </SectionItem>
                        <SectionItem className="align_left">
                            <VersionChanges
                                changes={releaseNote.majorChanges}
                            />
                        </SectionItem>
                        {releaseNote.minorChanges && (
                            <>
                                <Spacing height="10px" />
                                <SectionItem className="align_left">
                                    <h4>Minor Changes</h4>
                                </SectionItem>
                                <SectionItem className="align_left">
                                    <VersionChanges
                                        size="small"
                                        changes={releaseNote.minorChanges}
                                    />
                                </SectionItem>
                            </>
                        )}
                    </PageSection>
                </>
            ))}
        </>
    );
}

export { TopSection, ReleaseNotesSection };
