import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useBarType } from './providers/WindowProvider';

import NavBar from './components/common/navbar/NavBar';
import SideBar from './components/common/sidebar/SideBar';
import Home from './components/pages/home/Home';
import Coding from './components/pages/coding/Coding';
import Art from './components/pages/art/Art';
import Blog from './components/pages/blog/Blog';
import ReleaseNotes from './components/pages/release_notes/ReleaseNotes';
import NotFound from './components/pages/not_found/NotFound';
import Footer from './components/common/footer/Footer';

function App() {
  const location = useLocation();

  // Set if sidebar is active or not
  let sideBarActive = useBarType() === 'sidebar';

  // Automatically scrolls to top whenever location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={sideBarActive ? 'horizontal' : undefined}>
      {sideBarActive ? <SideBar /> : <NavBar />}
      <div className="expand">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/coding" element={<Coding />} />
          <Route path="/art" element={<Art />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/release-notes" element={<ReleaseNotes />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
