import { useEffect, useState } from 'react';

import { codingContent } from '../../../assets/content/Contents';
import {
    useWindowSize,
    useWindowSizeLimits,
} from '../../../providers/WindowProvider';
import {
    PageSection,
    SectionRow,
    SectionItem,
    Spacing,
} from '../../common/page_components/PageComponents';
import PlaceholderMessage from '../../common/placeholder/PlaceholderMessage';

import './Coding.css';

function ExplanationSection() {
    return (
        <PageSection id="coding_explanation_section">
            <SectionItem>
                <p>
                    <b>{codingContent.explanationTextHighlight}</b>{' '}
                    {codingContent.explanationText}
                </p>
            </SectionItem>
        </PageSection>
    );
}

function ProjectsSection() {
    const windowSize = useWindowSize();
    const windowSizeLimits = useWindowSizeLimits();
    const isScreenWidthSmall = windowSize.width < windowSizeLimits.small;
    const [columnCount, setColumnCount] = useState(isScreenWidthSmall ? 1 : 2);

    useEffect(() => {
        setColumnCount(isScreenWidthSmall ? 1 : 2);
    }, [isScreenWidthSmall]);

    function ProjectButton(props) {
        return (
            <button
                onClick={() => {
                    props.url && window.open(props.url, '_blank');
                }}
                className="outlined project_button"
            >
                <div className="project_button_container">
                    <h3 className="project_button_title">{props.title}</h3>
                    <p className="small project_button_text">{props.text}</p>
                </div>
            </button>
        );
    }

    function renderProjectsContent() {
        if (!codingContent.projects || codingContent.projects.length < 1) {
            return (
                <PlaceholderMessage
                    message={codingContent.noProjects}
                    className="expand"
                />
            );
        } else {
            return (
                <PageSection className="expand align_top">
                    <SectionRow
                        columnCount={columnCount}
                        className="expand align_top"
                    >
                        {codingContent.projects.map((project, key) => {
                            return (
                                <SectionItem key={key} className="expand">
                                    <ProjectButton
                                        title={project.title}
                                        text={project.text}
                                        url={project.url}
                                    />
                                </SectionItem>
                            );
                        })}
                    </SectionRow>
                </PageSection>
            );
        }
    }

    return renderProjectsContent();
}

function GitHubSection() {
    function GitHubButton() {
        return (
            <button
                onClick={() => {
                    window.open('https://github.com/aliemrenebiler/', '_blank');
                }}
                className="outlined"
                id="github_button"
            >
                @aliemrenebiler on GitHub
            </button>
        );
    }

    return (
        <PageSection>
            <SectionItem>
                <h2 id="github_title">{codingContent.gitHubTitle}</h2>
            </SectionItem>
            <SectionItem>
                <p>{codingContent.gitHubText}</p>
            </SectionItem>
            <Spacing height="10px" />
            <SectionItem>
                <GitHubButton />
            </SectionItem>
        </PageSection>
    );
}

export { ExplanationSection, ProjectsSection, GitHubSection };
