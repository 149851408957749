import { commonContent } from '../../../assets/content/Contents';
import { Page } from '../../common/page_components/PageComponents';
import PlaceholderMessage from '../../common/placeholder/PlaceholderMessage';

function NotFound() {
    return (
        <Page>
            <PlaceholderMessage
                message={commonContent.notFound}
                className="full_height"
            />
        </Page>
    );
}

export default NotFound;
